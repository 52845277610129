import { Injectable } from '@angular/core';

import { Block } from 'src/app/components/slack-blocks/common';
import {
  ApprovalRequest,
  Conversation,
  ConversationMessage,
  ExternalTask,
  ListRequest,
  ListResponse,
  WhereClause,
} from 'src/models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  constructor(private apiService: ApiService) {}

  draftsCache: Record<string, any> = {};
  cachedConversation?: Conversation;

  create(data: any): Promise<Partial<Conversation>> {
    return this.apiService.postPromise(`/conversations`, data);
  }

  get(id: string, select?: string[]): Promise<Partial<Conversation>> {
    return this.apiService.getPromise(`/conversations/${id}${select?.length ? `?select=${select.join(',')}` : ''}`);
  }

  update(id: string, updates: any): Promise<Partial<Conversation>> {
    return this.apiService.patchPromise(`/conversations/${id}`, updates);
  }

  delete(id: string): Promise<Partial<Conversation>> {
    return this.apiService.deletePromise(`/conversations/${id}`);
  }

  bulkUpdate(where: WhereClause[], updates: any): Promise<Partial<Conversation>[]> {
    return this.apiService.patchPromise(`/conversations/bulk`, {
      where,
      data: updates,
    });
  }

  list(options: ListRequest = {}, useFullContent = false): Promise<ListResponse<Partial<Conversation>>> {
    return this.apiService.postPromise(`/conversations/list?useFullContent=${useFullContent}`, options);
  }

  getMessages(conversationId: string, options: ListRequest = {}): Promise<ListResponse<Partial<ConversationMessage>>> {
    return this.apiService.postPromise(`/conversations/${conversationId}/messages/list`, options);
  }

  triage(
    conversationId: string,
    channelId: string | null,
    note: string,
    tagUserSlackId?: string,
    createChannel?: { isPrivate: boolean; prefix: string },
  ): Promise<any> {
    return this.apiService.postPromise(`/conversations/${conversationId}/triage`, {
      note,
      channelId,
      createChannel,
      tagUserSlackId,
    });
  }

  getTriageMessages(
    conversationId: string,
    options: ListRequest = {},
  ): Promise<ListResponse<Partial<ConversationMessage>>> {
    return this.apiService.postPromise(`/conversations/${conversationId}/triage-messages/list`, options);
  }

  getAuditLog(id: string): Promise<any[]> {
    return this.apiService.getPromise(`/conversations/${id}/audit-log`);
  }

  getTasks(conversationId: string): Promise<ExternalTask[]> {
    return this.apiService.getPromise(`/conversations/${conversationId}/tasks`);
  }

  getTags(conversationId: string): Promise<any[]> {
    return this.apiService.getPromise(`/conversations/${conversationId}/tags`);
  }

  approveTag(conversationId: string, tagId: string): Promise<any[]> {
    return this.apiService.postPromise(`/conversations/${conversationId}/tags/${tagId}/approve`, {});
  }

  createMessage(
    conversationId: string,
    html: string,
    blocks?: Block[],
    triageThreadTs?: string,
    conversationUpdates?: any,
    attachments: any[] = [],
    isPrivateNote?: boolean,
  ): Promise<Partial<ConversationMessage>> {
    let body: any = {
      body: { type: 'html', value: html },
      blocks,
      triageThreadTs,
      conversationUpdates,
      isPrivateNote,
    };

    if (attachments.length) {
      const multipartBody = new FormData();

      multipartBody.append('json', JSON.stringify(body));

      for (const attachment of attachments) {
        multipartBody.append('attachments[]', attachment, attachment.name);
      }

      body = multipartBody;
    }

    return this.apiService.postPromise(`/conversations/${conversationId}/messages`, body);
  }

  deleteMessage(conversationId: string, messageId: string): Promise<Partial<ConversationMessage>> {
    return this.apiService.deletePromise(`/conversations/${conversationId}/messages/${messageId}`);
  }

  addReactionToMessage(conversationId: string, messageId: string, reaction: string): Promise<any> {
    return this.apiService.postPromise(`/conversations/${conversationId}/messages/${messageId}/add-reaction`, {
      reaction,
    });
  }

  removeReactionFromMessage(conversationId: string, messageId: string, reaction: string): Promise<any> {
    return this.apiService.postPromise(`/conversations/${conversationId}/messages/${messageId}/remove-reaction`, {
      reaction,
    });
  }

  linkToExternalTask(conversationId: string, remoteTaskId: string, providerConfig?: any) {
    return this.apiService.postPromise(`/external-task-management/link`, {
      conversationId,
      remoteTaskId,
      providerConfig,
    });
  }

  unlinkExternalTask(conversationId: string, remoteTaskId: string) {
    return this.apiService.postPromise(`/external-task-management/unlink`, {
      conversationId,
      remoteTaskId,
    });
  }

  merge(conversationId: string, otherConversationId: string) {
    return this.apiService.postPromise(`/conversations/${conversationId}/merge`, {
      otherConversationId,
    });
  }

  getApprovalRequests(conversationId: string) {
    return this.apiService.getPromise<Partial<ApprovalRequest>[]>(`/conversations/${conversationId}/approval-requests`);
  }

  createApprovalRequest(
    conversationId: string,
    data: { title: string; assignedToUserId?: string; assignedToGroupId?: string },
  ) {
    return this.apiService.postPromise<ApprovalRequest>(`/conversations/${conversationId}/approval-requests`, data);
  }

  deleteApprovalRequest(conversationId: string, approvalRequestId: string) {
    return this.apiService.deletePromise(`/conversations/${conversationId}/approval-requests/${approvalRequestId}`);
  }

  splitConversation(conversationId: string, messageId: string) {
    return this.apiService.postPromise(`/conversations/${conversationId}/split`, {
      messageId,
    });
  }
}
